var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vx-row" },
    [
      _c(
        "div",
        {
          staticClass: "vx-col w-full mb-base",
          attrs: { set: (_vm.orderdata = this.ordersList) },
        },
        [
          _c("vx-card", { staticClass: "mb-5" }, [
            _c(
              "div",
              { staticClass: "flex flex-wrap justify-end" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "mr-4 sm:mb-0 mb-2",
                    on: { click: _vm.launchEmailDialog },
                  },
                  [_vm._v("Send Email Confirmation")]
                ),
                _c(
                  "vs-button",
                  {
                    staticClass: "mr-4 sm:mb-0 mb-2",
                    on: {
                      click: function ($event) {
                        _vm.showSendToBackOfficeDialog = true
                      },
                    },
                  },
                  [_vm._v("Send To Backoffice")]
                ),
              ],
              1
            ),
          ]),
          _c("vx-card", { staticClass: "mb-5" }, [
            _c("div", { staticClass: "table-grid-item" }, [
              _c("div", { staticClass: "label-field" }, [
                _c("h4", [_vm._v("Order ID:")]),
              ]),
              _c("div", { staticClass: "text-field" }, [
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.prefixedOrderId) },
                }),
              ]),
              _c("div", { staticClass: "label-field" }, [
                _c("h4", [_vm._v("Order Status:")]),
              ]),
              _c(
                "div",
                { staticClass: "text-field" },
                [
                  _c("v-select", {
                    attrs: {
                      clearable: false,
                      value: _vm.selectedOrderStatus,
                      options: _vm.orderStatusOptions,
                    },
                    on: { input: _vm.setOrderStatus },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "table-grid-item" }, [
              _c("div", { staticClass: "label-field" }, [
                _c("h4", [_vm._v("Customer Language:")]),
              ]),
              _c("div", { staticClass: "text-field" }, [
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(this.ordersList.orderLangCode),
                  },
                }),
              ]),
              _c("div", { staticClass: "label-field" }, [
                _c("h4", [_vm._v("Order Total:")]),
              ]),
              _c("div", { staticClass: "text-field" }, [
                _c("span", {
                  domProps: { innerHTML: _vm._s(this.ordersList.orderTotal) },
                }),
              ]),
            ]),
            _c("div", { staticClass: "table-grid-item" }, [
              _c("div", { staticClass: "label-field" }, [
                _c("h4", [_vm._v("Customer IP:")]),
              ]),
              _c("div", { staticClass: "text-field" }, [
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://whatismyipaddress.com/ip/" +
                        this.ordersList.orderIpAddress,
                      target: "_blank",
                    },
                  },
                  [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(this.ordersList.orderIpAddress),
                      },
                    }),
                  ]
                ),
              ]),
              _c("div", { staticClass: "label-field" }, [
                _c("h4", [_vm._v("Created:")]),
              ]),
              _c("div", { staticClass: "text-field" }, [
                _c("span", {
                  domProps: { innerHTML: _vm._s(this.ordersList.createdDate) },
                }),
              ]),
            ]),
            _c("div", { staticClass: "table-grid-item" }, [
              _c("div", { staticClass: "label-field" }, [
                _c("h4", [_vm._v("Booking Agent")]),
              ]),
              _c(
                "div",
                { staticClass: "text-field" },
                [
                  _c("v-select", {
                    staticClass: "w-4/5",
                    attrs: {
                      clearable: false,
                      value: _vm.selectedBookingAgent,
                      options: _vm.sortedBookingAgentOptions,
                    },
                    on: { input: _vm.setBookingAgent },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "label-field" }, [
                _c("h4", [_vm._v("Marketing Sources:")]),
              ]),
              _c(
                "div",
                { staticClass: "text-field multi-line" },
                _vm._l(this.ordersList.orderSourceList, function (item, index) {
                  return _c("span", {
                    key: index,
                    domProps: {
                      innerHTML: _vm._s(
                        item.sourceName + " : " + item.sourceValue
                      ),
                    },
                  })
                }),
                0
              ),
            ]),
          ]),
          _c("vx-card", { staticClass: "mb-5" }, [
            _c(
              "div",
              { staticClass: "flex flex-wrap justify-end" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "mr-4 sm:mb-0 mb-2",
                    attrs: { type: "border", size: "small" },
                    on: {
                      click: function ($event) {
                        _vm.showEditOrderDialog = true
                      },
                    },
                  },
                  [_vm._v("Edit")]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "table-grid-item" }, [
              _c("div", { staticClass: "label-field" }, [
                _c("h4", [_vm._v("Customer ID:")]),
              ]),
              _vm.ordersList.customerId
                ? _c("div", { staticClass: "text-field d-flex" }, [
                    _c("span", [_vm._v(_vm._s(this.ordersList.customerId))]),
                    _c("span", { staticClass: "text-link" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "/applications/" +
                              _vm.applicationId +
                              "/customers/" +
                              _vm.ordersList.customerId +
                              "/view",
                          },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(" (" + "View in CRM" + ")") +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                  ])
                : _c("div", { staticClass: "text-field d-flex" }),
              _c("div", { staticClass: "label-field" }, [
                _c("h4", [_vm._v("Internal Reference:")]),
              ]),
              _c("div", { staticClass: "text-field" }, [
                _vm.ordersList.orderRef
                  ? _c("span", {
                      domProps: { innerHTML: _vm._s(_vm.ordersList.orderRef) },
                    })
                  : _c("span", [_vm._v("N/A")]),
              ]),
            ]),
            _c("div", { staticClass: "table-grid-item" }, [
              _c("div", { staticClass: "label-field" }, [
                _c("h4", [_vm._v("Customer Name:")]),
              ]),
              _c("div", { staticClass: "text-field multi-line" }, [
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(
                      this.ordersList.orderCustomer == null
                        ? ""
                        : this.ordersList.orderCustomer.customerName
                    ),
                  },
                }),
              ]),
              _vm.ordersList.specialRequest
                ? _c("div", { staticClass: "label-field" }, [
                    _c("h4", [_vm._v("Special Request:")]),
                  ])
                : _vm._e(),
              _vm.ordersList.specialRequest
                ? _c("div", { staticClass: "text-field" }, [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.ordersList.specialRequest),
                      },
                    }),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "table-grid-item-col-2" }, [
              _c("div", { staticClass: "label-field" }, [
                _c("h4", [_vm._v("Customer Email:")]),
              ]),
              _c("div", { staticClass: "text-field multi-line" }, [
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(
                      this.ordersList.orderCustomer == null
                        ? ""
                        : this.ordersList.orderCustomer.customerEmail
                    ),
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "table-grid-item-col-2" }, [
              _c("div", { staticClass: "label-field" }, [
                _c("h4", [_vm._v("Customer Tel:")]),
              ]),
              _c("div", { staticClass: "text-field multi-line" }, [
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(
                      this.ordersList.orderCustomer == null ||
                        this.ordersList.orderCustomer.customerTel
                          .telephoneNumber == ""
                        ? ""
                        : this.ordersList.orderCustomer.customerTel
                            .telephoneType +
                            ": " +
                            this.ordersList.orderCustomer.customerTel
                              .telephonePrefix +
                            " " +
                            this.ordersList.orderCustomer.customerTel
                              .telephoneNumber
                    ),
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "table-grid-item-col-2" }, [
              _c("div", { staticClass: "label-field" }, [
                _c("h4", [_vm._v("Customer Address:")]),
              ]),
              _c("div", { staticClass: "text-field multi-line" }, [
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(
                      this.ordersList.orderCustomer == null ||
                        this.ordersList.orderCustomer.customerAddress == null
                        ? ""
                        : this.ordersList.orderCustomer.customerAddress
                            .addressName
                    ),
                  },
                }),
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(
                      this.ordersList.orderCustomer == null ||
                        this.ordersList.orderCustomer.customerAddress == null
                        ? ""
                        : this.ordersList.orderCustomer.customerAddress
                            .addressLine1
                    ),
                  },
                }),
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(
                      this.ordersList.orderCustomer == null ||
                        this.ordersList.orderCustomer.customerAddress == null
                        ? ""
                        : this.ordersList.orderCustomer.customerAddress
                            .addressLine2
                    ),
                  },
                }),
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(
                      this.ordersList.orderCustomer == null ||
                        this.ordersList.orderCustomer.customerAddress == null
                        ? ""
                        : this.ordersList.orderCustomer.customerAddress
                            .addressCity
                    ),
                  },
                }),
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(
                      this.ordersList.orderCustomer == null ||
                        this.ordersList.orderCustomer.customerAddress == null
                        ? ""
                        : this.ordersList.orderCustomer.customerAddress
                            .addressState
                    ),
                  },
                }),
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(
                      this.ordersList.orderCustomer == null ||
                        this.ordersList.orderCustomer.customerAddress == null
                        ? ""
                        : this.ordersList.orderCustomer.customerAddress
                            .addressPostalCode
                    ),
                  },
                }),
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(
                      this.ordersList.orderCustomer == null ||
                        this.ordersList.orderCustomer.customerAddress == null
                        ? ""
                        : this.ordersList.orderCustomer.customerAddress
                            .addressCountryCode
                    ),
                  },
                }),
              ]),
            ]),
          ]),
          _c(
            "vx-card",
            { staticClass: "mb-5 order-details" },
            [
              _c("ag-grid-vue", {
                ref: "agGridTable",
                staticClass: "ag-theme-material w-100 my-4",
                attrs: {
                  components: _vm.cellRendererComponents,
                  columnDefs: _vm.columnDefs,
                  defaultColDef: _vm.defaultColDef,
                  rowData: _vm.productList,
                  gridOptions: _vm.gridOptions,
                  enableCellTextSelection: true,
                  domLayout: "autoHeight",
                  id: "order-detail-grid",
                  context: _vm.context,
                },
                on: {
                  "first-data-rendered": _vm.onFirstDataRendered,
                  "grid-size-changed": _vm.onGridSizeChanged,
                },
              }),
            ],
            1
          ),
          this.ordersList && this.ordersList.orderDeposit
            ? _c(
                "vx-card",
                { staticClass: "mb-5 order-details" },
                [
                  _c("div", { staticClass: "vx-col w-full" }, [
                    _c("h4", { staticClass: "mb-4" }, [
                      _vm._v("Deposit Breakdown"),
                    ]),
                  ]),
                  _c("order-deposit", {
                    attrs: { depositData: this.ordersList.orderDeposit },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "vx-card",
            { staticClass: "mb-5" },
            [
              _vm.orderPaymentsList && _vm.orderPaymentsList.length > 0
                ? _c("order-payment-grid", {
                    attrs: { paymentItems: _vm.orderPaymentsList },
                  })
                : _c("div", { staticClass: "text-center" }, [
                    _vm._v("No payments made"),
                  ]),
              _c("div", { staticClass: "vx-row" }, [
                _c("div", { staticClass: "vx-col w-1/2 mt-3" }, [
                  _vm.orderPaymentsList && _vm.orderPaymentsList.length > 0
                    ? _c("div", { staticClass: "vx-row mt-2" }, [
                        _c(
                          "h5",
                          { staticClass: "vx-col w-2/5 font-semibold" },
                          [_vm._v("Balance Remaining:")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "vx-col w-3/5",
                            staticStyle: {
                              "font-size": "16px",
                              "font-weight": "400",
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  this.ordersList.orderBalanceRemaining +
                                    " " +
                                    this.ordersList.orderCurrencyCode
                                )
                              ),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "vx-col w-1/2" }, [
                  this.ordersList.orderBalanceRemaining > 0
                    ? _c(
                        "div",
                        { staticClass: "flex flex-wrap justify-end" },
                        [
                          _c(
                            "vs-button",
                            {
                              attrs: { type: "border", color: "success" },
                              on: { click: _vm.openAddPaymentPopup },
                            },
                            [_vm._v("Add Payment")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
              _vm.orderVoucherCodes && _vm.orderVoucherCodes.length > 0
                ? _c("div", { staticClass: "label-field" }, [
                    _c("h5", { staticClass: "pb-0" }, [
                      _vm._v("Voucher Codes"),
                    ]),
                  ])
                : _vm._e(),
              _vm.orderVoucherCodes && _vm.orderVoucherCodes.length > 0
                ? _c("voucher-codes", {
                    attrs: { voucherCodes: _vm.orderVoucherCodes },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._l(this.ordersList.orderDetailsList, function (item, index) {
            return _c(
              "vx-card",
              {
                key: index,
                staticClass: "mb-5",
                attrs: { set: (_vm.itemdata = JSON.parse(item.itemData)[0]) },
              },
              [
                item.productId == "1"
                  ? _c("flight-order-item", {
                      attrs: { orderData: _vm.ordersList, orderItem: item },
                    })
                  : _vm._e(),
                item.productId == "2"
                  ? _c("accomodation-item", {
                      attrs: {
                        orderItem: item,
                        itemdata: _vm.itemdata,
                        orderdata: _vm.ordersList,
                      },
                    })
                  : _vm._e(),
                item.productId == "3"
                  ? _c("package-holidays-item", {
                      attrs: {
                        orderData: _vm.ordersList,
                        itemdata: _vm.itemdata,
                        orderItem: item,
                      },
                    })
                  : _vm._e(),
                item.productId == "4"
                  ? _c("tickets-attractions-item", {
                      attrs: {
                        orderData: _vm.ordersList,
                        itemdata: _vm.itemdata,
                        orderItem: item,
                      },
                    })
                  : _vm._e(),
                item.productId == "5"
                  ? _c("taxis-transfer-item", {
                      attrs: {
                        orderData: _vm.ordersList,
                        itemdata: _vm.itemdata,
                        orderItem: item,
                      },
                    })
                  : _vm._e(),
                item.productId == "6"
                  ? _c("airport-extras-item", {
                      attrs: {
                        orderData: _vm.ordersList,
                        itemdata: _vm.itemdata,
                        orderItem: item,
                      },
                    })
                  : _vm._e(),
                item.productId == "7"
                  ? _c("insurance-item", {
                      attrs: { orderData: _vm.ordersList, orderItem: item },
                    })
                  : _vm._e(),
                item.productId == "8"
                  ? _c("car-rental-item", {
                      attrs: {
                        orderData: _vm.ordersList,
                        itemdata: _vm.itemdata,
                        orderItem: item,
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          }),
        ],
        2
      ),
      _vm.showSendEmailDialog
        ? _c("send-email-confirmation-dialog", {
            attrs: {
              applicationId: _vm.applicationId,
              orderId: _vm.orderId,
              customerEmail: _vm.confirmationEmailAddress,
            },
            on: {
              onSuccessSend: function ($event) {
                _vm.showSendEmailDialog = false
              },
              onCancel: function ($event) {
                _vm.showSendEmailDialog = false
              },
            },
          })
        : _vm._e(),
      _vm.showSendToBackOfficeDialog
        ? _c("send-to-backoffice-dialog", {
            attrs: { applicationId: _vm.applicationId, orderId: _vm.orderId },
            on: {
              onSuccessSend: function ($event) {
                _vm.showSendToBackOfficeDialog = false
              },
              onCancel: function ($event) {
                _vm.showSendToBackOfficeDialog = false
              },
            },
          })
        : _vm._e(),
      _vm.showEditOrderDialog
        ? _c("edit-order-dialog", {
            attrs: {
              applicationId: _vm.applicationId,
              orderId: _vm.orderId,
              ordersList: _vm.ordersList,
            },
            on: {
              onSaveSuccess: function ($event) {
                return _vm.$router.go()
              },
              onCancel: function ($event) {
                _vm.showEditOrderDialog = false
              },
            },
          })
        : _vm._e(),
      _vm.showCancelOrderItemDialog
        ? _c("cancel-order-item-dialog", {
            attrs: {
              applicationId: _vm.applicationId,
              orderId: _vm.orderId,
              orderItemData: _vm.cancelOrderItemData,
            },
            on: {
              onCancelOrderItemSuccess: function ($event) {
                return _vm.$router.go()
              },
              onCancel: function ($event) {
                _vm.showCancelOrderItemDialog = false
              },
            },
          })
        : _vm._e(),
      _vm.showEditOrderItemDialog
        ? _c("edit-order-item-dialog", {
            attrs: {
              itemId: _vm.editOrderItemId,
              applicationId: _vm.applicationId,
              orderId: _vm.orderId,
              productList: _vm.productList,
              currencyOptions: _vm.currencyOptions,
            },
            on: {
              onEditOrderItemSuccess: function ($event) {
                return _vm.$router.go()
              },
              onCancel: function ($event) {
                _vm.showEditOrderItemDialog = false
              },
            },
          })
        : _vm._e(),
      _vm.showAddPaymentDialog
        ? _c("add-payment-dialog", {
            attrs: {
              applicationId: _vm.applicationId,
              orderId: _vm.orderId,
              orderCurrencyCode: this.ordersList.orderCurrencyCode,
              balanceRemaining: this.ordersList.orderBalanceRemaining,
            },
            on: {
              onCancel: function ($event) {
                _vm.showAddPaymentDialog = false
              },
              onAddPaymentSuccess: _vm.handleAddPaymentSuccess,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }