var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-modal",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showAddPaymentDialog,
          expression: "showAddPaymentDialog",
        },
      ],
      attrs: { width: "560", height: "330", zIndex: "999" },
    },
    [
      _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v("Add Payment"),
      ]),
      _c("div", { attrs: { slot: "body" }, slot: "body" }, [
        _c("div", { staticClass: "vx-row mb-2 mt-4" }, [
          _c("div", { staticClass: "vx-col w-1/4 pt-1" }, [
            _c("span", [_vm._v("Amount")]),
          ]),
          _c("div", { staticClass: "vx-col w-3/4" }, [
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-4/5" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|greaterThanZero",
                        expression: "'required|greaterThanZero'",
                      },
                    ],
                    staticClass: "w-full",
                    attrs: { type: "number", name: "Payment Amount" },
                    model: {
                      value: _vm.addPaymentData.amount,
                      callback: function ($$v) {
                        _vm.$set(_vm.addPaymentData, "amount", _vm._n($$v))
                      },
                      expression: "addPaymentData.amount",
                    },
                  }),
                  _c("span", { staticClass: "text-danger text-sm" }, [
                    _vm._v(_vm._s(_vm.errors.first("Payment Amount"))),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "vx-col w-1/5 mt-2" }, [
                _c("div", [_vm._v(_vm._s(_vm.addPaymentData.currencyCode))]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "vx-row mb-2 mt-4" }, [
          _c("div", { staticClass: "vx-col w-1/4 pt-1" }, [
            _c("span", [_vm._v("Payment Supplier")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col w-3/4" },
            [
              _c("v-select", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: {
                  name: "Payment Supplier",
                  reduce: (x) => x.code,
                  clearable: true,
                  "append-to-body": "",
                  options: _vm.paymentSupplierOptions,
                },
                model: {
                  value: _vm.addPaymentData.paymentSupplierId,
                  callback: function ($$v) {
                    _vm.$set(_vm.addPaymentData, "paymentSupplierId", $$v)
                  },
                  expression: "addPaymentData.paymentSupplierId",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Payment Supplier"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-2 mt-4" }, [
          _c("div", { staticClass: "vx-col w-1/4 pt-1" }, [
            _c("span", [_vm._v("Payment Method")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col w-3/4" },
            [
              _c("v-select", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: {
                  name: "Payment Method",
                  reduce: (x) => x.code,
                  clearable: true,
                  "append-to-body": "",
                  options: _vm.paymentMethodOptions,
                },
                model: {
                  value: _vm.addPaymentData.paymentMethodId,
                  callback: function ($$v) {
                    _vm.$set(_vm.addPaymentData, "paymentMethodId", $$v)
                  },
                  expression: "addPaymentData.paymentMethodId",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Payment Method"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-2 mt-4" }, [
          _c("div", { staticClass: "vx-col w-1/4 pt-1" }, [
            _c("span", [_vm._v("Payment Reference")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col w-3/4" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "max:250",
                    expression: "'max:250'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "Payment Reference" },
                model: {
                  value: _vm.addPaymentData.paymentReference,
                  callback: function ($$v) {
                    _vm.$set(_vm.addPaymentData, "paymentReference", $$v)
                  },
                  expression: "addPaymentData.paymentReference",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Payment Reference"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-2 mt-4" }, [
          _c("div", { staticClass: "vx-col w-1/4 pt-1" }, [
            _c("span", [_vm._v("Payment Notes")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col w-3/4" },
            [
              _c("vs-textarea", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "max:500",
                    expression: "'max:500'",
                  },
                ],
                staticClass: "w-full mb-0",
                attrs: { name: "Payment Notes", rows: "2" },
                model: {
                  value: _vm.addPaymentData.paymentNotes,
                  callback: function ($$v) {
                    _vm.$set(_vm.addPaymentData, "paymentNotes", $$v)
                  },
                  expression: "addPaymentData.paymentNotes",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Payment Notes"))),
              ]),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "flex flex-wrap justify-center",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "vs-button",
            {
              attrs: { color: "primary", type: "filled" },
              on: { click: _vm.saveOrderPayment },
            },
            [_vm._v("Save")]
          ),
          _c(
            "vs-button",
            {
              staticClass: "ml-2",
              attrs: { color: "danger", type: "filled" },
              on: { click: _vm.close },
            },
            [_vm._v("Cancel")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }