var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: { direction: _vm.$vs.rtl ? "rtl" : "ltr" } },
    [
      _c("feather-icon", {
        attrs: {
          title: "Edit",
          icon: "Edit2Icon",
          svgClasses: "h-5 w-5 mr-4 hover:text-primary cursor-pointer",
        },
        on: { click: _vm.onEdit },
      }),
      _vm.params.data.itemStatus == 4
        ? _c("feather-icon", {
            attrs: {
              title: "Cancel",
              icon: "XCircleIcon",
              svgClasses: "h-5 w-5 mr-4 hover:text-primary cursor-pointer",
            },
            on: { click: _vm.onCancelOrderItem },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }