var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-modal",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showSendEmailDialog,
          expression: "showSendEmailDialog",
        },
      ],
      attrs: { width: "430", height: "140", zIndex: "99999" },
    },
    [
      _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v("Send Email Confirmation"),
      ]),
      _c("div", { attrs: { slot: "body" }, slot: "body" }, [
        _c("div", { staticClass: "vx-row mb-2 mt-4" }, [
          _c(
            "div",
            { staticClass: "vx-col w-full" },
            [
              _c("label", { staticClass: "text-sm mb-2" }, [
                _vm._v("Email Address(es) - "),
                _c("span", [
                  _vm._v(" (use comma to separate multiple emails)"),
                ]),
              ]),
              _c("vs-textarea", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|mustBeEmailList|noNewLine",
                    expression: "'required|mustBeEmailList|noNewLine'",
                  },
                ],
                staticClass: "w-full mb-0",
                attrs: { name: "Email Address", rows: "2" },
                model: {
                  value: _vm.confirmationEmailAddress,
                  callback: function ($$v) {
                    _vm.confirmationEmailAddress = $$v
                  },
                  expression: "confirmationEmailAddress",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Email Address"))),
              ]),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "flex flex-wrap justify-center",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "vs-button",
            {
              attrs: {
                disabled: _vm.errors.any(),
                color: "primary",
                type: "filled",
              },
              on: { click: _vm.sendEmailConfirmation },
            },
            [_vm._v("Send Email")]
          ),
          _c(
            "vs-button",
            {
              staticClass: "ml-2",
              attrs: { color: "danger", type: "border" },
              on: { click: _vm.close },
            },
            [_vm._v("Cancel")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }