var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-modal",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showBackofficeDialog,
          expression: "showBackofficeDialog",
        },
      ],
      attrs: { width: "430", height: "60", zIndex: "99999" },
    },
    [
      _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v("Send To Backoffice Confirmation"),
      ]),
      _c("div", { attrs: { slot: "body" }, slot: "body" }, [
        _c("div", { staticClass: "vx-row mb-2 mt-4" }, [
          _c("div", { staticClass: "vx-col w-full" }, [
            _c("div", [_vm._v("Are you sure you want to send to backoffice?")]),
          ]),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "flex flex-wrap justify-center",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "vs-button",
            {
              attrs: {
                disabled: _vm.errors.any(),
                color: "primary",
                type: "filled",
              },
              on: { click: _vm.sendToBackoffice },
            },
            [_vm._v("Send")]
          ),
          _c(
            "vs-button",
            {
              staticClass: "ml-2",
              attrs: { color: "danger", type: "border" },
              on: { click: _vm.close },
            },
            [_vm._v("Cancel")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }