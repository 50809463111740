var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-modal",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showEditOrderItemDialog,
          expression: "showEditOrderItemDialog",
        },
      ],
      attrs: { width: "660", height: "350", zIndex: "999" },
    },
    [
      _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v("Edit Order Item Data"),
      ]),
      _c(
        "div",
        { attrs: { slot: "body" }, slot: "body" },
        [
          _vm.editableOrderItemData.errorMessage
            ? _c("vs-alert", { attrs: { color: "danger", active: "true" } }, [
                _vm._v(
                  "\n      Error: " +
                    _vm._s(_vm.editableOrderItemData.errorMessage) +
                    "\n    "
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "vx-row mb-2 mt-4" }, [
            _c("div", { staticClass: "vx-col w-1/4 pt-1" }, [
              _c("span", [_vm._v("Type")]),
            ]),
            _c("div", { staticClass: "vx-col w-3/4" }, [
              _c("span", [
                _vm._v(_vm._s(_vm.editableOrderItemData.productName)),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "vx-row mb-2 mt-4" }, [
            _c("div", { staticClass: "vx-col w-1/4 pt-1" }, [
              _c("span", [_vm._v("Supplier")]),
            ]),
            _c("div", { staticClass: "vx-col w-3/4" }, [
              _c("span", [
                _vm._v(_vm._s(_vm.editableOrderItemData.supplierName)),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "vx-row mb-2 mt-4" }, [
            _c("div", { staticClass: "vx-col w-1/4 pt-1" }, [
              _c("span", [_vm._v("Booking Reference")]),
            ]),
            _c(
              "div",
              { staticClass: "vx-col w-3/4" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "max:50",
                      expression: "'max:50'",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: { name: "Booking Reference" },
                  model: {
                    value: _vm.editableOrderItemData.bookingReference,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.editableOrderItemData,
                        "bookingReference",
                        $$v
                      )
                    },
                    expression: "editableOrderItemData.bookingReference",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("Booking Reference"))),
                ]),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row mb-2 mt-4" }, [
            _c("div", { staticClass: "vx-col w-1/4 pt-1" }, [
              _c("span", [_vm._v("Supplier Price")]),
            ]),
            _c(
              "div",
              { staticClass: "vx-col w-3/4" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { type: "number" },
                  model: {
                    value: _vm.editableOrderItemData.supplierPrice,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.editableOrderItemData,
                        "supplierPrice",
                        _vm._n($$v)
                      )
                    },
                    expression: "editableOrderItemData.supplierPrice",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row mb-2 mt-4" }, [
            _c("div", { staticClass: "vx-col w-1/4 pt-1" }, [
              _c("span", [_vm._v("Supplier Price Currency")]),
            ]),
            _c(
              "div",
              { staticClass: "vx-col w-3/4" },
              [
                _c("v-select", {
                  attrs: {
                    name: "Order Item Status",
                    reduce: (x) => x.code,
                    clearable: false,
                    "append-to-body": "",
                    options: _vm.currencyOptions,
                  },
                  model: {
                    value: _vm.editableOrderItemData.supplierPriceCurrency,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.editableOrderItemData,
                        "supplierPriceCurrency",
                        $$v
                      )
                    },
                    expression: "editableOrderItemData.supplierPriceCurrency",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row mb-2 mt-4" }, [
            _c("div", { staticClass: "vx-col w-1/4 pt-1" }, [
              _c("span", [_vm._v("Selling Price")]),
            ]),
            _c(
              "div",
              { staticClass: "vx-col w-3/4" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { type: "number" },
                  model: {
                    value: _vm.editableOrderItemData.sellingPrice,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.editableOrderItemData,
                        "sellingPrice",
                        _vm._n($$v)
                      )
                    },
                    expression: "editableOrderItemData.sellingPrice",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row mb-2 mt-4" }, [
            _c("div", { staticClass: "vx-col w-1/4 pt-1" }, [
              _c("span", [_vm._v("Status")]),
            ]),
            _c(
              "div",
              { staticClass: "vx-col w-3/4" },
              [
                _c("v-select", {
                  attrs: {
                    name: "Order Item Status",
                    reduce: (x) => x.code,
                    clearable: false,
                    "append-to-body": "",
                    options: _vm.orderItemStatusOptions,
                  },
                  model: {
                    value: _vm.editableOrderItemData.statusId,
                    callback: function ($$v) {
                      _vm.$set(_vm.editableOrderItemData, "statusId", $$v)
                    },
                    expression: "editableOrderItemData.statusId",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "flex flex-wrap justify-center",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "vs-button",
            {
              attrs: { color: "primary", type: "filled" },
              on: { click: _vm.saveEditOrderItemData },
            },
            [_vm._v("Save")]
          ),
          _c(
            "vs-button",
            {
              staticClass: "ml-2",
              attrs: { color: "danger", type: "filled" },
              on: { click: _vm.close },
            },
            [_vm._v("Cancel")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }