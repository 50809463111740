var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ag-grid-vue", {
    ref: "agGridTable",
    staticClass:
      "ag-theme-material w-100 my-4 ag-grid-table order-payment-grid",
    attrs: {
      components: _vm.cellRendererComponents,
      columnDefs: _vm.columnDefs,
      defaultColDef: _vm.defaultColDef,
      rowData: _vm.paymentItems,
      gridOptions: _vm.gridOptions,
      rowSelection: "single",
      pagination: false,
      suppressPaginationPanel: true,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }