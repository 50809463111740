var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-modal",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showEditOrderDialog,
          expression: "showEditOrderDialog",
        },
      ],
      attrs: { width: "650", height: "620", zIndex: "999" },
    },
    [
      _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v("Edit Order Data"),
      ]),
      _c("div", { attrs: { slot: "body" }, slot: "body" }, [
        _c("div", { staticClass: "vx-row mb-2" }, [
          _c("div", { staticClass: "vx-col w-1/4 pt-1" }, [
            _c("span", [_vm._v("Title")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col w-3/4" },
            [
              _c("v-select", {
                attrs: {
                  reduce: (x) => x.code,
                  clearable: false,
                  "append-to-body": "",
                  options: _vm.titleOptions,
                },
                model: {
                  value: _vm.editableOrderData.customerTitle,
                  callback: function ($$v) {
                    _vm.$set(_vm.editableOrderData, "customerTitle", $$v)
                  },
                  expression: "editableOrderData.customerTitle",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-2 mt-4" }, [
          _c("div", { staticClass: "vx-col w-1/4 pt-1" }, [
            _c("span", [_vm._v("Customer Name")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col w-3/4" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "max:50",
                    expression: "'max:50'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "Customer First Name" },
                model: {
                  value: _vm.editableOrderData.customerFirstName,
                  callback: function ($$v) {
                    _vm.$set(_vm.editableOrderData, "customerFirstName", $$v)
                  },
                  expression: "editableOrderData.customerFirstName",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Customer First Name"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-2 mt-4" }, [
          _c("div", { staticClass: "vx-col w-1/4 pt-1" }, [
            _c("span", [_vm._v("Customer Last Name")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col w-3/4" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "max:50",
                    expression: "'max:50'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "Customer Last Name" },
                model: {
                  value: _vm.editableOrderData.customerLastName,
                  callback: function ($$v) {
                    _vm.$set(_vm.editableOrderData, "customerLastName", $$v)
                  },
                  expression: "editableOrderData.customerLastName",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Customer Last Name"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-2" }, [
          _c("div", { staticClass: "vx-col w-1/4 pt-1" }, [
            _c("span", [_vm._v("Customer Email")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col w-3/4" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "max:250",
                    expression: "'max:250'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "Customer Email" },
                model: {
                  value: _vm.editableOrderData.customerEmail,
                  callback: function ($$v) {
                    _vm.$set(_vm.editableOrderData, "customerEmail", $$v)
                  },
                  expression: "editableOrderData.customerEmail",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Customer Email"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-2" }, [
          _c("div", { staticClass: "vx-col w-1/4 pt-1" }, [
            _c("span", [_vm._v("Telephone Type")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col w-3/4" },
            [
              _c("v-select", {
                attrs: {
                  name: "Telephone Type",
                  reduce: (x) => x.code,
                  clearable: false,
                  "append-to-body": "",
                  options: _vm.phoneTypeOptions,
                },
                model: {
                  value: _vm.editableOrderData.customerTelephoneType,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.editableOrderData,
                      "customerTelephoneType",
                      $$v
                    )
                  },
                  expression: "editableOrderData.customerTelephoneType",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Telephone Type"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-2" }, [
          _c("div", { staticClass: "vx-col w-1/4 pt-1" }, [
            _c("span", [_vm._v("Customer Telephone")]),
          ]),
          _c("div", { staticClass: "vx-col w-3/4 pt-1" }, [
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col pr-1 w-1/5" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      placeholder: "Prefix",
                      name: "Customer Telephone Prefix",
                    },
                    model: {
                      value: _vm.editableOrderData.customerTelephonePrefix,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.editableOrderData,
                          "customerTelephonePrefix",
                          $$v
                        )
                      },
                      expression: "editableOrderData.customerTelephonePrefix",
                    },
                  }),
                  _c("span", { staticClass: "text-danger text-sm" }, [
                    _vm._v(
                      _vm._s(_vm.errors.first("Customer Telephone Prefix"))
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col pl-1 w-4/5" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      placeholder: "Telephone Number",
                      name: "Customer Telephone Number",
                    },
                    model: {
                      value: _vm.editableOrderData.customerTelephoneNumber,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.editableOrderData,
                          "customerTelephoneNumber",
                          $$v
                        )
                      },
                      expression: "editableOrderData.customerTelephoneNumber",
                    },
                  }),
                  _c("span", { staticClass: "text-danger text-sm" }, [
                    _vm._v(
                      _vm._s(_vm.errors.first("Customer Telephone Number"))
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "vx-row mb-2" }, [
          _c("div", { staticClass: "vx-col w-1/4 pt-1" }, [
            _c("span", [_vm._v("Address Line 1")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col w-3/4" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "max:250",
                    expression: "'max:250'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "Address Line 1" },
                model: {
                  value: _vm.editableOrderData.customerAddressLine1,
                  callback: function ($$v) {
                    _vm.$set(_vm.editableOrderData, "customerAddressLine1", $$v)
                  },
                  expression: "editableOrderData.customerAddressLine1",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Address Line 1"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-2" }, [
          _c("div", { staticClass: "vx-col w-1/4 pt-1" }, [
            _c("span", [_vm._v("Address Line 2")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col w-3/4" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "max:250",
                    expression: "'max:250'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "Address Line 2" },
                model: {
                  value: _vm.editableOrderData.customerAddressLine2,
                  callback: function ($$v) {
                    _vm.$set(_vm.editableOrderData, "customerAddressLine2", $$v)
                  },
                  expression: "editableOrderData.customerAddressLine2",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Address Line 2"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-2" }, [
          _c("div", { staticClass: "vx-col w-1/4 pt-1" }, [
            _c("span", [_vm._v("City")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col w-3/4" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "max:150",
                    expression: "'max:150'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "Customer City" },
                model: {
                  value: _vm.editableOrderData.customerCity,
                  callback: function ($$v) {
                    _vm.$set(_vm.editableOrderData, "customerCity", $$v)
                  },
                  expression: "editableOrderData.customerCity",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Customer City"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-2" }, [
          _c("div", { staticClass: "vx-col w-1/4 pt-1" }, [
            _c("span", [_vm._v("State")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col w-3/4" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "max:150",
                    expression: "'max:150'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "Customer State" },
                model: {
                  value: _vm.editableOrderData.customerState,
                  callback: function ($$v) {
                    _vm.$set(_vm.editableOrderData, "customerState", $$v)
                  },
                  expression: "editableOrderData.customerState",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Customer State"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-2" }, [
          _c("div", { staticClass: "vx-col w-1/4 pt-1" }, [
            _c("span", [_vm._v("Postal Code")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col w-3/4" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "max:50",
                    expression: "'max:50'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "Customer Postal Code" },
                model: {
                  value: _vm.editableOrderData.customerPostalCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.editableOrderData, "customerPostalCode", $$v)
                  },
                  expression: "editableOrderData.customerPostalCode",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Customer Postal Code"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-2" }, [
          _c("div", { staticClass: "vx-col w-1/4 pt-1" }, [
            _c("span", [_vm._v("Country")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col w-3/4" },
            [
              _c("v-select", {
                attrs: {
                  name: "Country Code",
                  reduce: (x) => x.code,
                  clearable: false,
                  "append-to-body": "",
                  options: _vm.countryOptions,
                },
                model: {
                  value: _vm.editableOrderData.customerCountryCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.editableOrderData, "customerCountryCode", $$v)
                  },
                  expression: "editableOrderData.customerCountryCode",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Country Code"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-2 mt-4" }, [
          _c("div", { staticClass: "vx-col w-1/4 pt-1" }, [
            _c("span", [_vm._v("Internal Reference")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col w-3/4" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "max:50",
                    expression: "'max:50'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "Customer First Name" },
                model: {
                  value: _vm.editableOrderData.orderReference,
                  callback: function ($$v) {
                    _vm.$set(_vm.editableOrderData, "orderReference", $$v)
                  },
                  expression: "editableOrderData.orderReference",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Customer First Name"))),
              ]),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "flex flex-wrap justify-center",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "vs-button",
            {
              attrs: { color: "primary", type: "filled" },
              on: { click: _vm.saveEditOrderData },
            },
            [_vm._v("Save")]
          ),
          _c(
            "vs-button",
            {
              staticClass: "ml-2",
              attrs: { color: "danger", type: "filled" },
              on: { click: _vm.close },
            },
            [_vm._v("Cancel")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }