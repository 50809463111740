var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-modal",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showCancelOrderItemDialog,
          expression: "showCancelOrderItemDialog",
        },
      ],
      attrs: { width: "550", height: "390", zIndex: "99999" },
    },
    [
      _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v("Cancel Order Item"),
      ]),
      _c(
        "div",
        { attrs: { slot: "body" }, slot: "body" },
        [
          _c("dl", { staticClass: "text-sm" }, [_vm._v("Order Item Id")]),
          _c("dd", { staticClass: "mb-2" }, [
            _vm._v(_vm._s(_vm.orderItemData.orderItemId)),
          ]),
          _c("dl", { staticClass: "text-sm" }, [_vm._v("Type")]),
          _c("dd", { staticClass: "mb-2" }, [
            _vm._v(_vm._s(_vm.orderItemData.productName)),
          ]),
          _c("dl", { staticClass: "text-sm" }, [_vm._v("Supplier")]),
          _c("dd", { staticClass: "mb-2" }, [
            _vm._v(
              _vm._s(
                _vm.orderItemData.supplierName || "No supplier information"
              )
            ),
          ]),
          _c("dl", { staticClass: "text-sm mt-2" }, [
            _vm._v("Booking Reference"),
          ]),
          _c("dd", { staticClass: "mb-2" }, [
            _vm._v(
              _vm._s(
                _vm.orderItemData.itemBookingRef ||
                  "No Booking Reference information"
              )
            ),
          ]),
          _vm.policies
            ? _c("div", { staticClass: "text-sm" }, [
                _vm._v("Policy Information"),
              ])
            : _vm._e(),
          _vm._l(_vm.policies, function (policy, index) {
            return _c(
              "div",
              { key: index, staticClass: "vx-col w-full mt-2" },
              [_vm._v(_vm._s(policy))]
            )
          }),
          _vm.policies && _vm.policies.length === 0
            ? _c("div", { staticClass: "vx-col w-full" }, [
                _vm._v("No specified cancellation policy."),
              ])
            : _vm._e(),
          _c("div", { staticClass: "vx-row mt-4" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c("label", { staticClass: "text-sm" }, [
                  _vm._v("Cancellation Reason"),
                ]),
                _c("vs-textarea", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|max:250",
                      expression: "'required|max:250'",
                    },
                  ],
                  staticClass: "w-full mb-0",
                  attrs: { name: "Cancellation Reason", rows: "2" },
                  model: {
                    value: _vm.cancellationReason,
                    callback: function ($$v) {
                      _vm.cancellationReason = $$v
                    },
                    expression: "cancellationReason",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("Cancellation Reason"))),
                ]),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "flex flex-wrap justify-center",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "vs-button",
            {
              attrs: { color: "primary", type: "filled" },
              on: { click: _vm.confirmCancel },
            },
            [_vm._v("Confirm")]
          ),
          _c(
            "vs-button",
            {
              staticClass: "ml-2",
              attrs: { color: "danger", type: "border" },
              on: { click: _vm.close },
            },
            [_vm._v("Cancel")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }