var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ag-grid-vue", {
    ref: "agGridTable",
    staticClass: "ag-theme-material mb-3",
    attrs: {
      components: _vm.cellRendererComponents,
      columnDefs: _vm.columnDefs,
      defaultColDef: _vm.defaultColDef,
      rowData: _vm.voucherCodes,
      gridOptions: _vm.gridOptions,
      domLayout: "autoHeight",
      rowSelection: "single",
      pagination: false,
      suppressPaginationPanel: true,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }